/* eslint-disable no-undef */
/*
 * @Author: DY
 * @Date: 2020-05-21 14:32:34
 * @LastEditTime: 2021-08-09 11:45:40
 * @LastEditors: Please set LastEditors
 * @Description: 领导驾驶舱统计图脚本
 * @FilePath: \JT.Web\src\assets\js\charts\index.js
 */
// 图形对象
// 象形柱图
let drawPictorialCharts;
// 立体柱图
let stereogramBarCharts;
// 折线图
let drawLineChart;
// 水平柱图
let drawLevelBarChart;
// 标准柱图
let drawBarChart;
// 折线柱图
let lineColumnChart;
// 多层柱图
let multiStoreyLineChart;
// 水球图
let waterPoloChart;
// 环形图
let ringDiagramChart;
// 散点图
let scatterPlotChart;
// 词云图
let ciYunChart;
// 地图
let drawMapChart;

require('echarts-wordcloud');
import 'echarts/theme/macarons.js';

// 象形柱图
const drawPictorialBar = (value, data) => {
    drawPictorialCharts = window.$globalHub.$echarts.init(document.getElementById(value));
    // eslint-disable-next-line max-len
    const icon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFIAAAA2CAYAAABUSc7XAAAAAXNSR0IArs4c6QAABQRJREFUeAHtms+LHEUUx1/1jxndGAKiwZBoRHRVkEXiSTYgiYIiXgLCevasf4HnePUScvSa4MGDIAiuCfHkNYpIdj34IwQRFURIsGd6yu/3dffY7G71zM7ULJvhFdR2dVXX63qf9171MvVErBgBI2AEjIARMAL3BwEXWuaz14qPpZevSVl6j4eCD4YELEn/WPc0dVIMvr11vvfuXqple3VqX5at3d389KXB7R9FgNIPhyIj1pHeB+ctw4CD2yQJaiYuAyLc56eelpXzF4LaBUGC3WB45ycptr8TKYfiB/8C5gDtElwBc4mLc4CYpoCYi8v7aAMooJJJSO0gSE6AHwLcQAH6YTEGqV4ZkrgE/Z7e6FMlQG90SoJBHi5hkJAl8EA/qAD6osA92yXCG3WZS0JvTMWP6h2SXwlGI5kEShgkJngAYzhrSBOiQsU+ScH01qUs2B8ddUQ4U0t6J68TnKcTpIYw9kTdF7k3Yq/U/ZIfnCUuDvA8KdIzqT/3zAk6d4PER0U/LLSGVuya/NAs+ceGAecY1rXenvvlBJ27Qe7yOghvIlrDe9cD938H//VRHRtFp1OpY/ucToA9VREwkJE8wUAayEgEIokxjzSQkQhEEmMeaSAjEYgkxjzSQEYiEEmMeaSBjEQgkhjzSAMZiUAkMeaRBjISgUhizCMjgZzyF3IeYLC2y8779tg+221R0/7yzl+ym7K/H7ObWVNc+Y7WezpmdIPEoY8eBCUQxoXjUIj3/Cl+Wn073t0aapPgwtv3rcfGTa5nfFOtrXU7T1Ptw8Mu6qk641xb293B2w2SAniShkwDZhvwAEgB4upikhzLooVoJIKsYdaX/8FBMSqoIGuaqv08+FpzVTYchtkVdSUDBdt6bGezE6TTg/K8UgnHkdXaAZeKjpXfKXKf9zU0hadyayPx1I4QXYskF0BvqZXlVaFyZbFgqmzIpePkPRGmrbASZkcJg+TRNS1CYc0i6aE829YMhEbBDukThyqDMKOBaSF6dowjUK+JWgRZjauYWkGC1FAbewlDrwGppp741u4HIAPyFKTm/vQUpEYkmQRKGCQm6IJTWIMWp05QhpapzrbRMW+hCN0msELA865aqVqfHlmiT90Sa+EaUnojKguNrFtP3aeeWg3N9Zc6UhaTqKh7Bkdq3tUhOAgSHpJnJ04jXQUeSHXaaX3qkR1SpxqqDFGFdA2S2RzFPRn8vCXC9JiEYV7BJUAFhwjJT6+K6z0IReu9i2NN1BD4vIUerkaCsZjWB5lkQSYh0UGQyE+5uXLuAvRgzsYBFWXgHv/n6qXH7n79WRVSLZDMfFhZf1OOvvPebzDur7WzHsjiECUIxfJm6GUTzIcMmAgRHHr5Xv2r38irozu3P//r0ge90d9/cD+pHsNHJzn2iDz8/oeFO37yre112dxr/sL6KiMHadQbTuj1WL072Lr1sttMHj115aHXNxC+fXEPIIRZ0WZfcvzkle2z7suDXhfCOwiR9CaADAFebH/q5WL/xfU/86deENcHTFS22ZeWcnGxb59N+qEE+cMrbjs5euyjI6+9DW88opVt9nFsNlUXO+tQgqTK90ZyOX/yue/7z6wJK9vsWyyO2aVP+NjMLjjGzNUbfqP8/ZerlJWdeGLj1ln3SQy5i5BxqEHKdZ+tpuUXVHyrTN+Qc8xJtjITgee/Ks6wzjTZJhkBI2AEjIARMAJGYBeB/wByx9WyQgiIAQAAAABJRU5ErkJggg==';
    const newOption = {
        grid: {
            left: 50,
            top: 30,
            right: 20,
            bottom: 20,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: true,
                },
            },
        },
        xAxis: {
            data: data.xdata,
            axisTick: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,0.1)',
                    type: 'solid',
                    width: 1,
                },
            },
            axisLabel: {
                textStyle: {
                    color: '#fff',
                    fontSize: 12,
                },
            },
        },
        yAxis: [{
            splitNumber: 2,
            axisTick: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,0.1)',
                    width: 1,
                },
            },
            axisLabel: {
                textStyle: {
                    color: '#fff',
                },
            },
            splitArea: {
                areaStyle: {
                    color: 'rgba(255,255,255,.5)',
                },
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(255,255,255,0.1)',
                    width: 0.5,
                    type: 'dashed',
                },
            },
        }],
        series: [{
            name: data.yAxisName,
            type: 'pictorialBar',
            barCategoryGap: '0%',
            symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
            label: {
                show: true,
                position: 'top',
                distance: 15,
                color: '#26BFFA',
                fontWeight: 'bolder',
                fontSize: 20,
            },
            itemStyle: {
                normal: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0,
                            color: '#20E5FF', //  0%  处的颜色
                        },
                        {
                            offset: 1,
                            color: '#2F7EF2', //  100%  处的颜色
                        },
                        ],
                        global: false, //  缺省为  false
                    },
                    label: {
                        show: true,
                        position: 'top',
                        distance: 0,
                        color: '#26BFFA',
                        fontSize: 24,
                        fontFamily: 'technology',
                        backgroundColor: {
                            image: icon,
                        },
                        padding: [10, 15, 12, 15],
                        borderRadius: 100,
                    },
                },
                emphasis: {
                    opacity: 1,
                },
            },
            data: data.ydata[0].data,
            z: 10,
        }],
    };
    drawPictorialCharts.setOption(newOption);
};

// 立体柱图
const stereogramBar = (value, data) => {
    stereogramBarCharts = window.$globalHub.$echarts.init(document.getElementById(value));
    const option = {
        title: {
            text: data.volume_theory + '吨',
            subtext: data.source_name,
            x: 'center',
            y: '25%',
            z: 30,
            textStyle: {
                color: '#fff',
                fontSize: '10',
            },
            subtextStyle: {
                color: '#fff',
                fontSize: '10',

            },
        },
        tooltip: {
            show: false,
        },
        grid: {
            left: 0,
            top: 0,
            right: 0,
            bottom: 25,
        },
        xAxis: {
            data: ['' + data.stock_name + ''],
            axisTick: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#fff',
                    fontSize: '12',
                },
            },
        },
        yAxis: {
            splitLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
        },
        series: [],
    };
    let series;
    let barHeight = 0;
    const base = 40 / data.volume_max;
    if (data.volume_theory > 0) {
        barHeight = Math.ceil(data.volume_theory * base);
    } else {
        barHeight = 0;
    }
    if (barHeight > 40) {
        barHeight = 40;
    }
    let barColor;
    if (data.type_dic_code !== '208') {
        if (data.type_dic_code === '202') {
            barColor = ['#93EEA8', '#03A87B'];
        } else if (data.type_dic_code === '205-FMH') {
            barColor = ['#FB9264', '#E44642'];
        } else if (data.type_dic_code === '205-KZF') {
            barColor = ['#AB76E7', '#4231A3'];
        } else if (data.type_dic_code === '205-SHF') {
            barColor = ['#67C7FE', '#2065D5'];
        } else {
            barColor = ['#FB7D3C', '#FFC452'];
        }
        let tH = 0;
        if (barHeight > 45) {
            barHeight = 45;
            tH = 45 + 15;
        } else {
            tH = barHeight + 15;
        }
        series = [{
            type: 'pictorialBar',
            symbolSize: [40, 15],
            symbolOffset: [0, -58],
            z: 19,
            itemStyle: {
                normal: {
                    color: '#14b1eb',
                },
            },
            data: [{
                value: 1,
            }],
        }, {
            type: 'pictorialBar',
            symbol: 'rect',
            symbolSize: [40, 40],
            symbolOffset: [0, -25],
            itemStyle: {
                normal: {
                    color: '#1286b2',
                    opacity: 1,
                },
            },
            silent: true,
            data: [1],
            z: 2,
        }, {
            type: 'pictorialBar',
            symbolSize: [40, 15],
            symbolOffset: [0, -15],
            z: 12,
            itemStyle: {
                normal: {
                    // borderColor: '#E44642',
                    color: barColor[1],
                },
            },
            data: [1],
        }, {
            type: 'pictorialBar',
            symbolSize: [40, 40],
            symbol: 'diamond',
            itemStyle: {
                normal: {
                    color: barColor[1],
                    opacity: 1,
                },
            },
            data: [1],
            z: 1,
        }, {
            type: 'pictorialBar',
            symbolSize: [40, 15],
            symbolOffset: [0, -tH],
            z: 21,
            itemStyle: {
                normal: {
                    // borderColor: '#F6926E',
                    color: barColor[0],
                },
            },
            data: [1],
        }, {
            type: 'pictorialBar',
            symbol: 'rect',
            symbolSize: [40, barHeight],
            symbolOffset: [0, -20],
            itemStyle: {
                normal: {
                    color: new window.$globalHub.$echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [{
                            offset: 0,
                            color: barColor[0],
                        },
                        {
                            offset: 1,
                            color: barColor[1],
                        },
                        ]
                    ),
                },
            },
            silent: true,
            data: [1],
            z: 20,
        }];
    } else {
        let tH = 0;
        if (barHeight > 35) {
            barHeight = 35;
            tH = 35;
        } else {
            tH = barHeight;
        }
        barColor = ['#FCDD73', '#E6C436'];
        series = [{
            type: 'pictorialBar',
            symbolSize: [40, 15],
            symbolOffset: [0, -35],
            z: 12,
            itemStyle: {
                normal: {
                    color: '#14b1eb',
                },
            },
            data: [{
                value: 1,
            }],
        }, {
            type: 'pictorialBar',
            symbol: 'rect',
            symbolSize: [40, 40],
            symbolOffset: [0, -5],
            itemStyle: {
                normal: {
                    color: '#1286b2',
                    opacity: 1,
                },
            },
            silent: true,
            data: [1],
            z: 2,
        }, {
            type: 'pictorialBar',
            symbolSize: [40, 15],
            symbolOffset: [0, 0],
            z: 12,
            itemStyle: {
                normal: {
                    // borderColor: '#E44642',
                    color: barColor[1],
                },
            },
            data: [1],
        }, {
            type: 'pictorialBar',
            symbolSize: [40, 15],
            symbolOffset: [0, -tH],
            z: 21,
            itemStyle: {
                normal: {
                    // borderColor: '#F6926E',
                    color: barColor[0],
                },
            },
            data: [1],
        }, {
            type: 'pictorialBar',
            symbol: 'rect',
            symbolSize: [40, barHeight],
            symbolOffset: [0, -8],
            itemStyle: {
                normal: {
                    color: new window.$globalHub.$echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [{
                            offset: 0,
                            color: barColor[0],
                        },
                        {
                            offset: 1,
                            color: barColor[1],
                        },
                        ]
                    ),
                },
            },
            silent: true,
            data: [1],
            z: 20,
        }];
    }
    option.series = series;
    stereogramBarCharts.setOption(option);
};

// 折线图
const drawLine = (value, data) => {
    drawLineChart = window.$globalHub.$echarts.init(document.getElementById(value));
    const newOption = {
        animationEasing: 'elasticOut',
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: true,
                },
            },
        },
        legend: {
            show: true,
            top: 0,
            right: 0,
            data: data.legendData,
            textStyle: {
                color: data.fontColor ? data.fontColor : 'rgba(255,255,255,0.5)',
                fontSize: 12,
            },
            selected: {},
        },
        grid: {
            left: 40,
            top: 40,
            right: 10,
            bottom: 20,
        },
        dataZoom: [{
            show: false,
        }],
        xAxis: {
            type: 'category',
            data: data.xdata,
            axisTick: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: data.borderColor ? data.borderColor : 'rgba(255,255,255,0.1)',
                    type: 'solid',
                    width: 1,
                },
            },
            axisLabel: {
                textStyle: {
                    color: data.fontColor ? data.fontColor : 'rgba(255,255,255,0.5)',
                    fontSize: 12,
                },
            },
        },
        yAxis: [
            {
                name: data.yAxisName || '(方)',
                nameTextStyle: {
                    color: data.fontColor ? data.fontColor : 'rgba(255,255,255,0.5)',
                    fontSize: 12,
                },
                splitNumber: 5,
                type: 'value',
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: data.borderColor ? data.borderColor : 'rgba(255,255,255,0.1)',
                        width: 1,
                    },
                },
                axisLabel: {
                    textStyle: {
                        color: data.fontColor ? data.fontColor : 'rgba(255,255,255,0.5)',
                    },
                },
                splitArea: {
                    areaStyle: {
                        color: data.fontColor ? data.fontColor : 'rgba(255,255,255,.5)',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: data.borderColor ? data.borderColor : 'rgba(255,255,255,0.1)',
                        width: 0.5,
                        type: 'dashed',
                    },
                },
            },
        ],
        series: [],
    };
    if (data.grid) {
        newOption.grid = data.grid;
    }
    if (data.dataZoom) {
        newOption.dataZoom = data.dataZoom;
    }
    if (data.selected) {
        const newSelected = {};
        data.selected.forEach(item => {
            newSelected[item] = false;
        });
        newOption.legend.selected = newSelected;
    }
    data.ydata.forEach(item => {
        newOption.series.push({
            name: item.name,
            type: 'line',
            itemStyle: {
                normal: {
                    label: {
                        show: true,
                    },
                    color: item.color[0],
                    lineStyle: {
                        color: item.color[0],
                        width: 1,
                    },
                    areaStyle: {
                        color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                            offset: 0,
                            color: item.color[1],
                        }, {
                            offset: 1,
                            color: item.color[2],
                        }]),
                    },
                },
            },
            areaStyle: {
                normal: {
                    color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: item.color[1],
                    },
                    {
                        offset: 1,
                        color: item.color[2],
                    },
                    ], false),
                },
            },
            data: item.data,
        },);
    });
    drawLineChart.setOption(newOption, true);
};

// 水平柱图
const drawLevelBar = value => {
    drawLevelBarChart = window.$globalHub.$echarts.init(document.getElementById(value));
    drawLevelBarChart.setOption({
        grid: {
            left: 30,
            top: 0,
            right: 20,
            bottom: 20,
        },
        xAxis: {
            max: 10000,
            axisLine: {
                show: true,
                color: 'rgba(255,255,255,0.1)',
            },
            splitArea: {
                color: '#f00',
                lineStyle: {
                    color: '#f00',
                },
            },
            axisLabel: {
                color: '#fff',
            },
            splitLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        },
        yAxis: [{
            type: 'category',
            inverse: true,
            splitLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(255,255,255,0.1)',
                    width: 0.5,
                    type: 'dashed',
                },
            },
            axisLine: {
                show: true,
                color: 'rgba(255,255,255,0.1)',
            },
            axisLabel: {
                show: true,
                margin: 20,
                textStyle: {
                    color: '#d1e6eb',

                },
            },
            axisTick: {
                show: false,
            },
        }],
        series: [{
            // 内
            type: 'bar',
            barWidth: 18,

            legendHoverLink: false,
            silent: true,
            itemStyle: {
                normal: {
                    color: function () {
                        const color = {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 1,
                            y2: 0,
                            colorStops: [{
                                offset: 0,
                                color: '#2F7FF2', // 0% 处的颜色
                            },
                            {
                                offset: 1,
                                color: '#24CAFC', // 100% 处的颜色
                            },
                            ],
                        };
                        return color;
                    },
                },
            },
            label: {
                normal: {
                    show: true,
                    position: 'left',
                    formatter: '{b}',
                    textStyle: {
                        color: '#fff',
                        fontSize: 14,
                    },
                },
            },
            data: [{
                name: '1月',
                value: 2500,
            },
            {
                name: '2月',
                value: 8000,
            },
            {
                name: '3月',
                value: 3000,
            },
            {
                name: '4月',
                value: 3000,
            },
            {
                name: '5月',
                value: 3000,
            },
            ],
            z: 1,
            animationEasing: 'elasticOut',
        },
        {
            // 分隔
            type: 'pictorialBar',
            itemStyle: {
                normal: {
                    color: '#061348',
                },
            },
            symbolRepeat: 'fixed',
            symbolMargin: 3,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [4, 21],
            symbolPosition: 'start',
            symbolOffset: [6, -1],
            symbolBoundingData: 10000,
            data: [{
                name: '1月',
                value: 2500,
            },
            {
                name: '2月',
                value: 8000,
            },
            {
                name: '3月',
                value: 3000,
            },
            {
                name: '4月',
                value: 3000,
            },
            {
                name: '5月',
                value: 3000,
            },
            ],
            z: 2,
            animationEasing: 'elasticOut',
        },
        ],
    });
};

// 标准柱图
const drawBar = (value, data) => {
    drawBarChart = window.$globalHub.$echarts.init(document.getElementById(value));
    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: true,
                },
            },
        },
        legend: {
            textStyle: {
                color: '#fff',
            },
            right: 10,
        },
        grid: {
            left: 40,
            top: 30,
            right: 20,
            bottom: 20,
        },
        calculable: true,
        xAxis: [{
            type: 'category',
            data: [],
            axisLine: {
                show: true,
                color: 'rgba(255,255,255,0.1)',
            },
            splitArea: {
                color: '#f00',
                lineStyle: {
                    color: '#f00',
                },
            },
            axisLabel: {
                color: 'rgba(255,255,255,0.5)',
            },
            splitLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        }],
        yAxis: [],
        series: [],
    };
    if (data) {
        const series = [];
        option.xAxis[0].data = data.xAxis.data;
        option.yAxis.push({
            name: data.yAxis.name,
            nameTextStyle: {
                color: 'rgba(255,255,255,0.5)',
                padding: data.yAxis.nameTextStyle,
            },
            yAxisIndex: 1,
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(255,255,255,0.1)',
                    width: 0.5,
                    type: 'dashed',
                },
            },
            axisLine: {
                show: true,
                color: 'rgba(255,255,255,0.1)',
            },
            axisLabel: {
                show: true,
                color: 'rgba(255,255,255,0.5)',
            },
            axisTick: {
                show: false,
            },
        });
        data.series.forEach(item => {
            series.push({
                name: item.name,
                type: 'bar',
                barMaxWidth: item.barWidth,
                data: item.data,
                itemStyle: {
                    normal: {
                        color: new window.$globalHub.$echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [{
                                offset: 0,
                                color: item.color[0],
                            },
                            {
                                offset: 1,
                                color: item.color[1],
                            },
                            ]
                        ),
                    },
                },
            });
        });
        option.series = series;
    }
    drawBarChart.setOption(option);
};

// 折线柱图
const lineColumn = (obj, data) => {
    lineColumnChart = window.$globalHub.$echarts.init(document.getElementById(obj));
    const option = {
        title: {
            text: '',
            x: 'center',
            y: 0,
            textStyle: {
                color: '#B4B4B4',
                fontSize: 16,
                fontWeight: 'normal',
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: true,
                },
            },
        },
        legend: {
            textStyle: {
                color: '#fff',
            },
            top: 0,
            right: 10,
        },
        grid: {
            left: 60,
            top: 50,
            right: 60,
            bottom: 40,
        },
        xAxis: [{
            // type:'category',
            data: [],
            boundaryGap: true,
            axisLine: {
                show: true,
                color: 'rgba(255,255,255,0.1)',
            },
            splitArea: {
                color: '#f00',
                lineStyle: {
                    color: '#f00',
                },
            },
            axisLabel: {
                color: 'rgba(255,255,255,0.5)',
            },
            splitLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        }],
        yAxis: [],
        series: [],
    };
    if (data) {
        if (data.grid) {
            option.grid = data.grid;
        }

        const yAxis = [];
        const series = [];
        if (data.grid) {
            option.grid = data.grid;
        }
        option.xAxis[0].data = data.xAxis.data;
        data.yAxis.forEach(item => {
            if (item.show === 'false') {
                item.show = false;
            } else {
                item.show = true;
            }
            yAxis.push({
                name: item.name,
                // nameLocation: 'middle',
                nameTextStyle: {
                    color: 'rgba(255,255,255,0.5)',
                    padding: item.nameTextStyle,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: 'rgba(255,255,255,0.1)',
                        width: 0.5,
                        type: 'dashed',
                    },
                },
                axisLine: {
                    show: true,
                    color: 'rgba(255,255,255,0.1)',
                },
                axisLabel: {
                    show: true,
                    color: 'rgba(255,255,255,0.5)',
                },
                axisTick: {
                    show: false,
                },
                show: item.show,
            });
        });
        option.yAxis = yAxis;
        data.series.forEach(item => {
            let barYAxisIndex = 0;
            let lineYAxisIndex = 1;
            if (item.type === 'bar') {
                if (item.yAxisIndex || item.yAxisIndex === 0) {
                    barYAxisIndex = item.yAxisIndex;
                }
                series.push({
                    name: item.name,
                    type: 'bar',
                    barWidth: item.barWidth,
                    yAxisIndex: barYAxisIndex,
                    itemStyle: {
                        normal: {
                            color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: item.color[0],
                            },
                            {
                                offset: 1,
                                color: item.color[1],
                            },
                            ]),
                        },
                    },
                    data: item.data,
                });
            } else if (item.type === 'line') {
                if (item.yAxisIndex || item.yAxisIndex === 0) {
                    lineYAxisIndex = item.yAxisIndex;
                }
                series.push({
                    name: item.name,
                    type: 'line',
                    // smooth: true,
                    showSymbol: true,
                    symbolSize: 8,
                    lineStyle: {
                        normal: {
                            color: item.color[0],
                        },
                    },
                    yAxisIndex: lineYAxisIndex,
                    label: {
                        show: false,
                        position: 'top',
                        textStyle: {
                            color: item.color[0],
                        },
                    },
                    itemStyle: {
                        color: '#6c50f3',
                        borderColor: '#fff',
                        borderWidth: 1,
                        shadowColor: 'rgba(0, 0, 0, .3)',
                        shadowBlur: 0,
                        shadowOffsetY: 2,
                        shadowOffsetX: 2,
                        normal: {
                            color: item.color[0],
                            lineStyle: {
                                color: item.color[0],
                            },
                        },
                    },
                    data: item.data,
                });
            }
        });
        option.series = series;
    }
    lineColumnChart.setOption(option);
};

// 多层柱图
const multiStoreyLine = (obj, data) => {
    multiStoreyLineChart = window.$globalHub.$echarts.init(document.getElementById(obj));
    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: true,
                },
            },
        },
        grid: {
            left: 40,
            top: 30,
            right: 20,
            bottom: 20,
        },
        legend: {
            x: 'right',
            top: 'top',
            textStyle: {
                color: '#fff',
                fontSize: 14,
            },
            data: data.legend,
        },
        calculable: true,
        xAxis: [{
            type: 'category',
            boundaryGap: 'false',
            axisLine: {
                show: true,
                color: 'rgba(255,255,255,0.1)',
            },
            splitArea: {
                color: '#f00',
                lineStyle: {
                    color: '#f00',
                },
            },
            axisLabel: {
                color: 'rgba(255,255,255,0.5)',
            },
            splitLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            data: data.xAxis.data,
        }],
        yAxis: [{
            name: data.yAxis[0],
            nameTextStyle: {
                color: '#fff',
                fontSize: 12,
            },
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(255,255,255,0.1)',
                    width: 0.5,
                    type: 'dashed',
                },
            },
            axisLine: {
                show: true,
                color: 'rgba(255,255,255,0.1)',
            },
            axisLabel: {
                show: true,
                color: 'rgba(255,255,255,0.5)',
            },
            axisTick: {
                show: false,
            },
        }],
        series: [],
        color: ['#0AC664', '#FA904A', '#1FBECC', '#FFB205', '#5588F1', '#05A9F4', '#C754E0'],
    };
    const series = [];
    data.series.data.forEach(item => {
        if (item.type === 'bar') {
            item.data.forEach((k, index) => {
                series.push({
                    name: data.legend[index],
                    type: 'bar',
                    stack: '总量',
                    barMaxWidth: 20,
                    barGap: '10%',
                    data: k,
                });
            });
        } else if (item.type === 'line') {
            series.push({
                name: item.name,
                type: 'line',
                symbol: 'circle',
                stack: '总量',
                symbolSize: 10,
                itemStyle: {
                    normal: {
                        barBorderRadius: 0,
                        label: {
                            show: true,
                            position: 'top',
                        },
                    },
                },
                data: item.data,
            });
        }
    });
    option.series = series;
    multiStoreyLineChart.setOption(option);
};

// 水球图
const waterPolo = (value, data) => {
    waterPoloChart = window.$globalHub.$echarts.init(document.getElementById(value));
    const option = {
        series: [{
            type: 'liquidFill',
            radius: '85%',
            color: data.color,
            center: ['50%', '50%'],
            data: data.data,
            backgroundStyle: {
                color: 'rgba(255,255,255,0)',
            },
            outline: {
                show: true,
                borderDistance: 5,
                itemStyle: {
                    borderColor: data.color,
                    borderWidth: 4,
                },
            },
            label: {
                normal: {
                    textStyle: {
                        fontSize: 20,
                        color: data.color,
                    },
                },
            },
        }],
    };
    waterPoloChart.setOption(option);
};

// 环形图
const ringDiagram = (value, data) => {
    ringDiagramChart = window.$globalHub.$echarts.init(document.getElementById(value));
    const option = {
        title: {
            text: data.title,
            x: 'center',
            y: 'center',
            textStyle: {
                color: '#78D2F6',
                fontSize: '20',
            },
        },
        tooltip: {
            trigger: 'item',
            formatter: '{b} : {d}% <br/> {c}',
        },
        graphic: {
            elements: [{
                type: 'image',
                style: {
                    width: 100,
                    height: 100,
                },
                left: 'center',
                top: 'center',
            }],
        },
        series: [{
            type: 'pie',
            radius: ['55%', '70%'],
            center: ['50%', '50%'],
            color: ['#79D2F6', '#59B5DB', '#3899BC', '#2E809F', '#FFB205', '#66FCBF', '#5FDCA9', '#3EC090', '#12A277', '#00875B', '#026D42'],
            labelLine: {
                normal: {
                    show: true,
                    length: 15,
                    length2: 20,
                    lineStyle: {
                        color: '#78D2F6',
                        width: 2,
                    },
                },
            },
            label: {
                normal: {
                    formatter: '{a|{b}}\n{hr|}\n{d|{d}%}',
                    rich: {
                        b: {
                            fontSize: 14,
                            color: '#12EABE',
                            align: 'left',
                            padding: 4,
                        },
                        hr: {
                            borderColor: '#78D2F6',
                            width: '100%',
                            borderWidth: 2,
                            height: 0,
                        },
                        d: {
                            fontSize: 14,
                            color: '#78D2F6',
                            align: 'left',
                            padding: 4,
                        },
                        a: {
                            fontSize: 14,
                            color: '#78D2F6',
                            align: 'left',
                            padding: 4,
                        },
                    },
                },
            },
            data: data.data,
        }],
    };
    if (data.data.length < 1) {
        option.title.textStyle.color = 'rgba(255,255,255,0.3)';
        option.title.textStyle.fontSize = '50';
    }
    ringDiagramChart.setOption(option);
};

// 散点图
const scatterPlot = (value, data) => {
    scatterPlotChart = window.$globalHub.$echarts.init(document.getElementById(value));

    // eslint-disable-next-line max-len
    const hours = ['12a', '1a', '2a', '3a', '4a', '5a', '6a', '7a', '8a', '9a', '10a', '11a', '12p', '1p', '2p', '3p', '4p', '5p', '6p', '7p', '8p', '9p', '10p', '11p'];
    const days = ['周日', '周六', '周五', '周四', '周三', '周二', '周一'];

    data = data.map(function (item) {
        return [item[1], item[0], item[2]];
    });

    const option = {
        tooltip: {
            position: 'top',
            formatter: function (params) {
                return days[params.value[1]] + ' ' + hours[params.value[0]] + ' 发货 ' + params.value[2] + '车次';
            },
        },
        grid: {
            top: 0,
            left: 40,
            bottom: 20,
            right: 20,
        },
        xAxis: {
            type: 'category',
            data: hours,
            boundaryGap: false,
            splitLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(255,255,255,0.1)',
                    type: 'dashed',
                },
            },
            axisLabel: {
                interval: 0,
                color: 'rgba(255,255,255,0.5)',
            },
            axisLine: {
                show: false,
            },
        },
        yAxis: {
            type: 'category',
            data: days,
            axisLabel: {
                color: 'rgba(255,255,255,0.5)',
            },
            axisLine: {
                show: false,
            },
        },
        series: [{
            name: 'Punch Card',
            type: 'scatter',
            symbolSize: function (val) {
                return val[2] * 2;
            },
            data: data,
            animationDelay: function (idx) {
                return idx * 5;
            },
        }],
    };
    scatterPlotChart.setOption(option);
};

// 词云
const ciYun = (value, data) => {
    const datas = [];
    if (data.data && data.data.length > 0) {
        data.data.forEach((item, index) => {
            datas.push({
                name: item,
                value: index,
            });
        });
    } else {
        datas.push({
            name: '暂无数据',
            value: 1,
        });
    }
    ciYunChart = window.$globalHub.$echarts.init(document.getElementById(value));

    const option = {
        title: {
            text: data.title,
            x: 'left',
            y: 'top',
            textStyle: {
                fontSize: 14,
                color: 'rgba(255, 255, 255, 0.5)',
            },
        },
        tooltip: {
            show: true,
            position: 'top',
            textStyle: {
                fontSize: 16,
            },
        },
        series: [{
            type: 'wordCloud',
            // 网格大小，各项之间间距
            gridSize: 30,
            // 形状 circle 圆，cardioid  心， diamond 菱形，
            // triangle-forward 、triangle 三角，star五角星
            shape: 'circle',
            // 字体大小范围
            sizeRange: [16, 50],
            // 文字旋转角度范围
            rotationRange: [0, 0],
            // 旋转步值
            rotationStep: 90,
            // 自定义图形
            // maskImage: maskImage,
            left: 'center',
            top: 'center',
            right: null,
            bottom: null,
            // 画布宽
            width: '90%',
            // 画布高
            height: '80%',
            // 是否渲染超出画布的文字
            drawOutOfBound: false,
            textStyle: {
                normal: {
                    color: function () {
                        return 'rgb(' + [
                            Math.round(Math.random() * 200 + 55),
                            Math.round(Math.random() * 200 + 55),
                            Math.round(Math.random() * 200 + 55),
                        ].join(',') + ')';
                    },
                },
                emphasis: {
                    shadowBlur: 10,
                    shadowColor: '#2ac',
                },
            },
            data: datas,
        }],
    };
    ciYunChart.setOption(option);
};

// 地图
const drawMap = (value, data) => {
    $.get('./../../mapJson/china.json', function (geoJson) {
        window.$globalHub.$echarts.registerMap('china', geoJson);
        drawMapChart = window.$globalHub.$echarts.init(document.getElementById(value));

        const geoCoordMap = {
            东营: [118.674623, 37.434005],
            青州: [118.47949, 36.685215],
            济宁: [116.586961, 35.415353],

        };

        const convertData = function (data) {
            const res = [];
            for (let i = 0; i < data.length; i++) {
                const geoCoord = geoCoordMap[data[i].name];
                if (geoCoord) {
                    res.push({
                        name: data[i].name,
                        value: geoCoord.concat(data[i].transAmt),
                    });
                }
            }
            return res;
        };

        const mapOption = {
            visualMap: {
                show: false,
                left: 'right',
                min: 100,
                max: 3800,
                inRange: {
                    color: ['#016ABB', '#014FB1', '#2536AA', '#0055B0'],
                },
                text: ['High', 'Low'], // 文本，默认为数值文本
                calculable: true,
                seriesIndex: [0],
            },
            geo: {
                name: '1',
                type: 'map',
                map: 'china', // 类型,与上文中注册地图的名称一致
                zoom: 3, // 当前视角的缩放比例
                roam: true, // 是否开启平游或缩放
                label: {
                    normal: {
                        show: true,
                        color: '#04B3F4', // 每个区域文字颜色
                        fontSize: '16px',
                    },
                    emphasis: {
                        show: true,
                    },
                },
                mapLocation: {
                    y: 60,
                },
                itemStyle: {
                    emphasis: {
                        label: {
                            show: false,
                        },
                    },
                    normal: {
                        label: {
                            show: !0,
                        },
                        formatter: function formatter(a) {
                            return a.value;
                        },
                        borderColor: '#063E71', // 每个区域的边框色
                        areaColor: '#011837', // 区域背景色
                    },

                },
            },
            series: [{
                type: 'map',
                map: 'china',
                geoIndex: 0,
                aspectScale: 0.75, // 长宽比
                showLegendSymbol: false, // 存在legend时显示
                label: {
                    normal: {
                        show: true,
                        color: '#04B3F4', // 每个区域文字颜色
                        fontSize: '16px',
                    },
                    emphasis: {
                        show: true,
                    },
                },
                mapLocation: {
                    y: 60,
                },
                itemStyle: {
                    emphasis: {
                        label: {
                            show: false,
                        },
                    },
                    normal: {
                        label: {
                            show: !0,
                        },
                        formatter: function formatter(a) {
                            return a.value;
                        },
                        borderColor: '#063E71', // 每个区域的边框色
                        areaColor: '#011837', // 区域背景色
                    },

                },
                animation: false,
                data: [
                    {
                        name: '北京',
                        value: 125,
                    },
                    {
                        name: '河北',
                        value: 125,
                    },
                    {
                        name: '山东',
                        value: 125,
                    },
                    {
                        name: '河南',
                        value: 125,
                    },
                    {
                        name: '安徽',
                        value: 125,
                    },
                    {
                        name: '江苏',
                        value: 125,
                    },
                ],
            }, {

                symbolSize: 5,
                label: {
                    normal: {
                        formatter: '{b}',
                        position: 'right',
                        show: true,
                    },
                    emphasis: {
                        show: true,
                    },
                },
                itemStyle: {
                    normal: {
                        color: '#fff',
                    },
                },
                name: 'light',
                type: 'scatter',
                coordinateSystem: 'geo',
                data: convertData(data),

            }, {
                type: 'map',
                map: 'china',
                geoIndex: 0,
                aspectScale: 0.75, // 长宽比
                showLegendSymbol: false, // 存在legend时显示
                label: {
                    normal: {
                        show: false,
                    },
                    emphasis: {
                        show: false,
                        textStyle: {
                            color: '#fff',
                        },
                    },
                },
                roam: true,
                itemStyle: {
                    normal: {
                        areaColor: '#031525',
                        borderColor: '#FFFFFF',
                    },
                    emphasis: {
                        areaColor: '#2B91B7',
                    },
                },
                animation: false,
                data: data,
            }, {
                name: 'Top 5',
                type: 'scatter',
                coordinateSystem: 'geo',
                symbol: 'pin',
                symbolSize: [50, 50],
                label: {
                    normal: {
                        show: true,
                        textStyle: {
                            color: '#00392C',
                            fontSize: 20,
                            fontFamily: 'technology',
                        },
                        formatter(value) {
                            return value.data.value[2];
                        },
                    },
                },
                itemStyle: {
                    normal: {
                        color: '#5EC875', // 标志颜色
                    },
                },
                data: convertData(data),
                showEffectOn: 'render',
                rippleEffect: {
                    brushType: 'stroke',
                },
                hoverAnimation: true,
                zlevel: 1,
            }],
        };
        drawMapChart.setOption(mapOption);
    });
};

window.addEventListener('resize', function () {
    if (drawPictorialCharts) {
        drawPictorialCharts.resize();
    }
    if (stereogramBarCharts) {
        stereogramBarCharts.resize();
    }
    if (drawLineChart) {
        drawLineChart.resize();
    }
    if (drawLevelBarChart) {
        drawLevelBarChart.resize();
    }
    if (drawBarChart) {
        drawBarChart.resize();
    }
    if (lineColumnChart) {
        lineColumnChart.resize();
    }
    if (multiStoreyLineChart) {
        multiStoreyLineChart.resize();
    }
    if (waterPoloChart) {
        waterPoloChart.resize();
    }
    if (ringDiagramChart) {
        ringDiagramChart.resize();
    }
    if (scatterPlotChart) {
        scatterPlotChart.resize();
    }
    if (ciYunChart) {
        ciYunChart.resize();
    }
    if (drawMapChart) {
        drawMapChart.resize();
    }
});

export {
    drawPictorialBar,
    stereogramBar,
    drawLine,
    drawLevelBar,
    drawMap,
    drawBar,
    lineColumn,
    waterPolo,
    ringDiagram,
    scatterPlot,
    multiStoreyLine,
    ciYun,
};
